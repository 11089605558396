<template>
	<div id="nav-container" class="bg-asp-light-blue">
		<div class="container flex flex-row items-center mx-auto p-4">
			<button id="responsiveMenu" class="xl:hidden focus:outline-none h-8 sm:h-12" @click="menuToggle" @blur="handleHide">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</button>
			<div class="flex flex-row items-center w-full justify-center sm:justify-center lg:justify-center xl:justify-start xl:h-140px">
				<router-link class="flex h-10 lg:h-14 lg:ml-57px" :to="{ name: 'top' }">
					<img src="@/assets/images/logo.png" class="h-full" alt="Main Logo" />
				</router-link>
			</div>
		</div>
		<DashboardNav />
		<div class="overlay" @click="menuToggle"></div>
	</div>
</template>
<style scoped>
#responsiveMenu {
	width: 25px;
	height: 25px;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
}
#responsiveMenu span {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	background: #333;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}
#responsiveMenu span:nth-child(1) {
	top: 0px;
}

#responsiveMenu span:nth-child(2),
#responsiveMenu span:nth-child(3) {
	top: 9px;
}

#responsiveMenu span:nth-child(4) {
	top: 18px;
}

#nav-container.open #responsiveMenu span:nth-child(1) {
	top: 9px;
	width: 0%;
	left: 50%;
}

#nav-container.open #responsiveMenu span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

#nav-container.open #responsiveMenu span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

#nav-container.open #responsiveMenu span:nth-child(4) {
	top: 9px;
	width: 0%;
	left: 50%;
}

#nav-container.open #nav-sidebar {
	transform: translateX(0px);
	transition: transform ease-in-out 0.4s;
}
#nav-container.open .overlay {
	position: absolute;
	top: 72px;
	left: 0;
	bottom: 0;
	z-index: 30;
	background-color: #9e9e9f;
	width: 100%;
	height: 100%;
}
@media (min-width: 1024px) {
	#nav-container.open .overlay {
		top: 88px;
	}
}
</style>
<script>
import DashboardNav from './DashboardNav.vue';

export default {
	// eslint-disable-next-line vue/require-prop-types
	components: { DashboardNav },
	props: ['toggle', 'menuToggle', 'handleHide'],
	/** @type {{ menuToggle: () => void }} */
	emits: {
		menuToggle: null
	}
};
</script>
