<template>
	<div id="nav-sidebar" class="absolute border-r-2 border-gray-300 sm:w-570px block z-50">
		<ul class="h-full">
			<li>
				<router-link
					:to="{ name: 'puzzle' }"
					:class="[$route.name === 'puzzle' ? 'bg-blue-400' : 'bg-asp-F29670']"
					class="flex items-center font-bold text-white bg-center bg-no-repeat bg-contain border-white pl-8 md:pl-90px border-b-1 h-full hover:bg-blue-400 xl:text-2xl text-base"
				>
					パズル
				</router-link>
			</li>
			<li>
				<router-link
					:to="{ name: 'notifications' }"
					:class="[$route.name === 'notifications' ? 'bg-blue-400' : 'bg-asp-F29670']"
					class="flex items-center font-bold text-white bg-center bg-no-repeat bg-contain border-white  pl-8 md:pl-90px border-b-1 h-full hover:bg-blue-400 xl:text-2xl text-base relative"
					@click="handleReadNotifications"
				>
					通知
					<span v-if="state.notificationCount" class="bg-red-500 h-8 w-8 flex justify-center items-center rounded-full text-sm ml-6 absolute right-16">
						{{ state.notificationCount }}
					</span>
				</router-link>
			</li>
			<li>
				<router-link
					:to="{ name: 'message_list' }"
					:class="[$route.name === 'message_list' ? 'bg-blue-400' : 'bg-asp-F29670']"
					class="flex items-center font-bold text-white bg-center bg-no-repeat bg-contain border-white  pl-8 md:pl-90px border-b-1 h-full hover:bg-blue-400 xl:text-2xl text-base"
				>
					<span>メッセージ</span>
					<span v-if="notReadCount" class="bg-red-500 h-8 w-8 flex justify-center items-center rounded-full text-sm ml-6 absolute right-16">
						{{ notReadCount }}
					</span>
				</router-link>
			</li>
			<li>
				<router-link
					:to="{ name: 'invitation' }"
					:class="[$route.name === 'invitation' ? 'bg-blue-400' : 'bg-asp-F29670']"
					class="flex items-center font-bold text-white bg-center bg-no-repeat bg-contain border-white  pl-8 md:pl-90px border-b-1 h-full hover:bg-blue-400 xl:text-2xl text-base"
				>
					ともだち招待
				</router-link>
			</li>
			<li>
				<router-link
					:to="{ name: 'sign_up_guide' }"
					target="_blank"
					:class="[$route.name === 'sign_up_guide' ? 'bg-blue-400' : 'bg-asp-F29670']"
					class="flex items-center font-bold text-white bg-center bg-no-repeat bg-contain border-white pl-8 md:pl-90px border-b-1 h-full hover:bg-blue-400 xl:text-2xl text-base"
				>
					入会案内
				</router-link>
			</li>
			<li class="h-3x bg-white">
				<div class="flex flex-row sm:ml-47px items-center h-full">
					<div class="sm:w-125px w-24 h-24 mr-22px sm:h-125px ml-10">
						<img :src="globalState.user.icon_img || defaultAvatarImg" alt="User Avatar" class=" object-cover w-full h-full rounded-full" />
						<img v-if="globalState.user.user_type === 2" src="@/assets/images/supporter.png" alt="supporter" />
						<img v-if="globalState.user.user_type === 3" src="@/assets/images/special-supporter.png" alt="supporter" />
					</div>
					<div class="flex flex-col flex-1 mt-6">
						<h3 class="mb-1 font-bold text-sm md:text-xl">
							{{ globalState.user.name }}
						</h3>
						<p class="mb-3 text-sm md:text-xl">{{ globalState.user.nickname }}</p>
					</div>
				</div>
			</li>
			<li>
				<div class="border-t-1 border-asp-1A5EA2 w-full h-full">
					<router-link
						:to="{ name: 'my_page', params: { userId: globalState.user.id } }"
						:class="[$route.name === 'my_page' ? 'bg-blue-400' : 'bg-white']"
						class="flex justify-center font-bold text-center h-16 items-center text-asp-1A5EA2 text-sm md:text-xl w-full h-full"
					>
						マイページ
					</router-link>
				</div>
			</li>
			<li>
				<div class="border-t-1 border-asp-1A5EA2 w-full h-full">
					<router-link
						:to="{ name: 'setting' }"
						:class="[$route.name === 'setting' ? 'bg-blue-400' : 'bg-white']"
						class="flex justify-center font-bold h-16 items-center text-center text-asp-1A5EA2 text-sm md:text-xl w-full h-full"
					>
						設定
					</router-link>
				</div>
			</li>
			<li>
				<div class="h-full">
					<div class="flex border-t-1 py-12px border-asp-1A5EA2 border-b-1 justify-center w-full h-full bg-white">
						<button class=" text-center underline text-asp-light-gray text-sm md:text-xl w-full" @click="signOut">
							ログアウト
						</button>
					</div>
					<router-link :to="{ name: 'setting' }" class="h-31px w-31px ml-20px hidden">
						<img src="@/assets/images/gear-icon.png" alt="Setting Icon" class="w-full" />
					</router-link>
				</div>
			</li>
		</ul>
		<!-- <div class="flex flex-row justify-between mb-1 hidden">
			<router-link
				:to="{ name: 'platform' }"
				class="font-hiragino-kaku-gothic-std-w8 text-asp-white-gray text-19px"
				target="_blank"
				>プラットフォーム説明</router-link
			>
			<a
				href="#"
				class="text-lg font-hiragino-kaku-gothic-std-w8 text-asp-white-gray"
				target="_blank"
				>利用規約</a
			>
		</div>
		<div class="flex flex-row justify-between mb-4 hidden">
			<a
				href="#"
				class="font-hiragino-kaku-gothic-std-w8 text-asp-white-gray text-19px"
				target="_blank"
			>
				お問い合わせ
			</a>
		</div>
		<div class="mb-42px hidden">
			<h5 class="font-bold text-asp-white-gray text-19px">
				©️️2021 A START PIECE
			</h5>
		</div> -->
	</div>
</template>

<script>
import firebase from 'firebase/app';
import { reactive } from 'vue';
import { globalState, globalActions } from '@/stores/global';
import defaultAvatarImg from '@/assets/images/sample-user-avatar.png';
import { usePublicizedMessagesByUser } from '@/hooks/query';
import { f } from '@/services/utils';
import userApi from '@/apis/userApi';
import { notificationCol } from '@/apis/utils';
import notificationApi from '@/apis/notificationApi';

export default {
	// eslint-disable-next-line vue/require-prop-types
	props: {
		toggle: Boolean
	},
	setup() {
		const userId = f(globalState.user.id);
		const { notReadCount } = usePublicizedMessagesByUser(userId);
		const state = reactive({
			notificationCount: 0
		});

		const queryNotification = notificationCol.where('user_id', '==', globalState.user.id).onSnapshot(docs => {
			state.notificationCount = docs.docs.filter(noti => !noti.data().read).length;
		});

		// onMounted(() => {
		// 	const sidebar = document.getElementById('nav-sidebar');
		// 	const height = window.innerHeight;
		// 	const width = window.innerWidth;
		// 	document.getElementById('nav-container').classList.add('open');
		// 	if (height > width) {
		// 		document.body.classList.add('fixed', 'w-full');
		// 		sidebar.style.height = `${height - 144}px`;
		// 	} else {
		// 		document.body.classList.remove('fixed', 'w-full');
		// 		sidebar.style.height = `auto`;
		// 	}
		// });
		return {
			globalState,
			notReadCount,
			userId,
			defaultAvatarImg,
			isShowingDropdown: false,
			state,
			queryNotification
		};
	},
	watch: {
		$route(to, from) {
			userApi.getByDocId(this.userId).then(async response => {
				if (response.account_lock_flag || response?.user_deactive_flag) {
					await firebase.auth().signOut();
					globalActions.setUser({});
					window.location.href = '/';
				}
			});
		}
	},
	methods: {
		async signOut() {
			await firebase.auth().signOut();
			globalActions.setUser({});
			this.$router.push({ name: 'top' });
		},
		handleReadNotifications() {
			setTimeout(() => {
				notificationApi.setReadAllNotifications(globalState.user.id);
			}, 1000);
		}
	}
};
</script>

<style scoped>
#nav-container #nav-sidebar {
	left: 0;
	width: 80%;
	transform: translateX(-100%);
	transition: transform ease-in-out 0.4s;
}
#nav-container #nav-sidebar ul li {
	height: 10%;
}
#nav-container #nav-sidebar ul li.h-3x {
	height: 20%;
}
@media screen and (orientation: landscape) {
	body {
		position: static!;
	}
	#nav-container #nav-sidebar {
		width: 100%;
	}
	#nav-container #nav-sidebar ul li {
		height: auto;
	}
	#nav-container #nav-sidebar ul li.h-3x > div {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	#nav-container #nav-sidebar ul li a {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}
</style>
