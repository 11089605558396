<template>
	<div class="min-h-screen">
		<dashboard-header :toggle="toggle" :menu-toggle="menuToggle" :handle-hide="handleHide" />
		<div class="container mx-auto border-gray-300 lg:flex xl:border-l-2 xl:border-r-2">
			<dashboard-sidebar :toggle="toggle" />
			<!-- NOTE Add key to make router rerender page when change paramId -->
			<!-- <notification /> -->
			<router-view :key="$route.fullPath" />
		</div>
	</div>
	<full-page-footer />
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/messaging';
import DashboardSidebar from '@/layouts/components/DashboardSidebar.vue';
import DashboardHeader from '@/layouts/components/DashboardHeader.vue';
import userApi from '@/apis/userApi';
import { globalActions } from '@/stores/global';
import firebaseConfig from '@/firebaseConfig';
import FullPageFooter from './components/FullPageFooter.vue';
// import Notification from './components/Notification.vue';

export default {
	components: {
		DashboardSidebar,
		DashboardHeader,
		FullPageFooter
		// eslint-disable-next-line vue/no-unused-components
		// Notification
	},
	setup() {
		(async () => {
			try {
				/** @type {t.storage.SignedInUser['data']}  */
				const localUser = JSON.parse(localStorage.getItem('signedInUser') || '{}');
				globalActions.setUser({
					id: localUser.id,
					email: localUser.email
				});
				const firestoreUser = await userApi.getByDocId(localUser.id);
				if (firestoreUser?.account_lock_flag || firestoreUser?.user_deactive_flag || !firestoreUser) {
					await firebase.auth().signOut();
					globalActions.setUser({});
					window.location.href = '/';
				} else {
					const userData = {
						...localUser,
						...firestoreUser
					};
					globalActions.setUser(userData);
				}
			} catch (error) {
				console.log(error);
			}
		})();

		return {};
	},
	data() {
		return {
			toggle: false
		};
	},
	watch: {
		$route() {
			this.toggle = false;
		},
		toggle() {
			if (this.toggle) {
				document.getElementById('nav-container').classList.add('open');
				this.handleResize();
			} else {
				document.getElementById('nav-container').classList.remove('open');
				document.body.classList.remove('fixed', 'w-full');
			}
		}
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	unmounted() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		handleResize() {
			const sidebar = document.getElementById('nav-sidebar');
			const height = window.innerHeight;
			const width = window.innerWidth;
			if (height > width) {
				if (this.toggle) {
					document.body.classList.add('fixed', 'w-full');
					sidebar.style.height = `${height - 72}px`;
				}
			} else {
				document.body.classList.remove('fixed', 'w-full');
				sidebar.style.height = `auto`;
			}
		},
		menuToggle() {
			this.toggle = !this.toggle;
		},
		handleHide() {
			setTimeout(() => {
				if (!this.toggle) return;
				this.toggle = false;
			}, 200);
		}
	}
};
</script>
<style scoped></style>
