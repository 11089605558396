<template>
	<div>
		<div class=" w-507px hidden xl:block h-full ">
			<div class="px-16 border-r-2 border-gray-300 h-full">
				<div class="pt-46px mb-42px">
					<router-link
						:to="{ name: 'puzzle' }"
						:class="[$route.name === 'puzzle' ? 'bg-dashboard-item-up-blue' : 'bg-dashboard-item-down-orange']"
						class="flex items-center justify-center mx-auto font-bold text-white bg-center bg-no-repeat bg-contain h-100px w-368px hover:bg-dashboard-item-up-blue text-33px mb-32px"
					>
						パズル
					</router-link>
					<router-link
						:to="{ name: 'notifications' }"
						:class="[$route.name === 'notifications' ? 'bg-dashboard-item-up-blue' : 'bg-dashboard-item-down-orange']"
						class="flex items-center justify-center mx-auto font-bold text-white bg-center bg-no-repeat bg-contain h-100px w-368px hover:bg-dashboard-item-up-blue text-33px mb-32px relative"
						@click="handleReadNotifications"
					>
						通知
						<span v-if="state.notificationCount" class="bg-red-500 h-8 w-8 flex justify-center items-center rounded-full text-sm absolute right-16">
							{{ state.notificationCount }}
						</span>
					</router-link>
					<router-link
						:to="{ name: 'message_list' }"
						:class="[['message_list', 'message_detail'].includes(fString($route.name)) ? 'bg-dashboard-item-up-blue' : 'bg-dashboard-item-down-orange']"
						class="flex items-center justify-center mx-auto font-bold text-white bg-center bg-no-repeat bg-contain h-100px w-368px hover:bg-dashboard-item-up-blue text-33px mb-32px relative"
					>
						メッセージ
						<span v-if="notReadCount" class="bg-red-500 h-8 w-8 flex justify-center items-center rounded-full text-sm absolute right-16">
							{{ notReadCount }}
						</span>
					</router-link>
					<router-link
						:to="{ name: 'invitation' }"
						:class="[$route.name === 'invitation' ? 'bg-dashboard-item-up-blue' : 'bg-dashboard-item-down-orange']"
						class="flex items-center justify-center mx-auto font-bold text-white bg-center bg-no-repeat bg-contain h-100px w-368px hover:bg-dashboard-item-up-blue text-33px mb-32px"
					>
						ともだち招待
					</router-link>
					<router-link
						:to="{ name: 'sign_up_guide' }"
						:class="[$route.name === 'sign_up_guide' ? 'bg-dashboard-item-up-blue' : 'bg-dashboard-item-down-orange']"
						class="flex items-center justify-center mx-auto font-bold text-white bg-center bg-no-repeat bg-contain h-100px w-368px hover:bg-dashboard-item-up-blue text-33px mb-32px"
						target="_blank"
					>
						入会案内
					</router-link>
				</div>
				<div class="flex flex-row">
					<div class="w-125px mr-22px h-125px">
						<img :src="globalState.user.icon_img || defaultAvatarImg" alt="User Avatar" class="  object-cover w-full h-full rounded-full" />
						<img v-if="globalState.user.user_type === 2" src="@/assets/images/supporter.png" alt="supporter" />
						<img v-if="globalState.user.user_type === 3" src="@/assets/images/special-supporter.png" alt="supporter" />
					</div>
					<div class="flex flex-col flex-1 mb-26px">
						<!-- <h3 class="mb-1 font-bold text-24px">{{ globalState.user.name }}</h3> -->
						<p class="mb-3 text-24px">{{ globalState.user.nickname }}</p>
						<div class="flex flex-row items-center">
							<router-link
								:to="{ name: 'my_page', params: { userId: globalState.user.id } }"
								class="flex items-center justify-center block rounded-full w-167px h-41px border-3 border-asp-blue bg-asp-yellow text-asp-blue 18px-derailment font-aiko-w4"
							>
								マイページ
							</router-link>
							<router-link :to="{ name: 'setting' }" class="h-31px w-31px ml-20px">
								<img src="@/assets/images/gear-icon.png" alt="Setting Icon" class="w-full" />
							</router-link>
						</div>
					</div>
				</div>
				<div class="flex flex-col justify-center mb-32px">
					<button class="text-center underline text-asp-light-gray text-24px focus:outline-none " @click="signOut">
						ログアウト
					</button>
				</div>
				<div class="flex flex-row justify-between mb-1">
					<router-link :to="{ name: 'platform' }" class="font-hiragino-kaku-gothic-std-w8 text-asp-white-gray text-19px" target="_blank">
						あすたーとぴーすとは？
					</router-link>
					<router-link :to="{ name: 'policy' }" class="text-lg font-hiragino-kaku-gothic-std-w8 text-asp-white-gray" target="_blank">利用規約</router-link>
				</div>
				<div class="flex flex-row justify-between mb-4">
					<router-link :to="{ name: 'contact_form' }" class="font-hiragino-kaku-gothic-std-w8 text-asp-white-gray text-19px" target="_blank">
						お問い合わせ
					</router-link>
				</div>
				<div class="mb-42px">
					<h5 class="font-bold text-asp-white-gray text-19px">
						一般社団法人 A Start Piece Lab.
						<span>
							https://astartpiece.com/
						</span>
					</h5>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import firebase from 'firebase/app';
import { reactive } from 'vue';
import { globalState, globalActions } from '@/stores/global';
import defaultAvatarImg from '@/assets/images/sample-user-avatar.png';
import { usePublicizedMessagesByUser } from '@/hooks/query';
import { f, fString } from '@/services/utils';
import { notificationCol } from '@/apis/utils';
import notificationApi from '@/apis/notificationApi';
import firebaseConfig from '@/firebaseConfig';
import userApi from '@/apis/userApi';

export default {
	// eslint-disable-next-line vue/require-prop-types
	props: {
		toggle: Boolean
	},
	setup() {
		const userId = f(globalState.user.id);
		const { data: messages, notReadCount } = usePublicizedMessagesByUser(userId);
		const state = reactive({
			notificationCount: 0
		});

		const queryNotification = notificationCol.where('user_id', '==', globalState.user.id).onSnapshot(docs => {
			state.notificationCount = docs.docs.filter(noti => !noti.data().read).length;
		});
		const messaging = firebase.messaging();
		messaging
			.requestPermission()
			.then(() => {
				console.log('Notification permission granted.');

				// Get Token
				messaging
					.getToken({
						vapidKey: firebaseConfig.vapidKey
					})
					.then(token => {
						// console.log(token);
						userApi.update(userId, {
							message_token: token
						});
					});
			})
			.catch(err => {
				console.log('Unable to get permission to notify.', err);
			});

		return {
			globalState,
			messages,
			notReadCount,
			fString,
			defaultAvatarImg,
			isShowingDropdown: false,
			state,
			queryNotification
		};
	},
	unmounted() {
		this.queryNotification();
	},
	methods: {
		async signOut() {
			await userApi.update(f(globalState.user.id), {
				message_token: null
			});
			await firebase.auth().signOut();
			globalActions.setUser({});
			this.$router.push({ name: 'top' });
		},
		handleReadNotifications() {
			setTimeout(() => {
				notificationApi.setReadAllNotifications(globalState.user.id);
			}, 1000);
		}
	}
};
</script>
