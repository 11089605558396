<template>
	<div class="flex bg-asp-brown footer-fixed " :class="[$route.name === 'top' ? 'hidden xl:block' : '']">
		<div class="flex flex-row items-center justify-between w-full px-4 lg:h-80px lg:pl-57px lg:pr-68px md:h-72px h-auto">
			<a class="hidden px-4 h-52px lg:inline-block lg:mr-8" href="#">
				<img src="@/assets/images/logo-white.png" class="h-full" alt="Footer Logo" />
			</a>
			<div class="flex flex-col md:flex-row justify-between align-items-center w-full text-white lg:w-3/5 font-hiragino-kaku-gothic-std-w8 lg:text-19px">
				<div class="lg:mr-70px text-center">
					<router-link :to="{ name: 'platform' }" class="text-12px font-bold lg:text-lg focus:outline-none block pt-2" target="_blank">
						あすたーとぴーすとは？
					</router-link>
				</div>
				<div class="lg:mr-70px text-center">
					<router-link :to="{ name: 'term-of-use' }" class="text-12px font-bold lg:text-lg focus:outline-none block pt-2" target="_blank">利用規約</router-link>
				</div>
				<div class="lg:mr-70px text-center">
					<router-link :to="{ name: 'policy' }" class="text-12px font-bold lg:text-lg focus:outline-none block pt-2" target="_blank">プライバシーポリシー</router-link>
				</div>
				<div class="lg:mr-70px text-center">
					<router-link :to="{ name: 'disclaimer' }" class="text-12px font-bold lg:text-lg focus:outline-none block pt-2" target="_blank">免責事項</router-link>
				</div>
				<div class="text-center">
					<router-link :to="{ name: 'contact_form' }" class="text-12px font-bold lg:text-lg focus:outline-none block py-2" target="_blank">お問い合わせ</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
